<template>
  <master-detail
    descriptionProperty="chave_nfe"
    formTitle="Nota Fiscal/Item"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    :contextOptions="options"
    :formWidth="1400"
    :hasNewButton="false"
    :hasOneMonthFilter="true"
    :opts="opts"
    :resourceUrl="resourceUrl"
    :serverPagination="true"
  ></master-detail>
</template>

<script>
export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    resourceUrl: function () {
      return `/v1/faturamento/devolucao/${this.getClient().clientId}`;
    },
    resourceCFOPs: function () {
      return this.apiResource(`/v1/faturamento/cfop`);
    },
    options: function () {
      return this.userHasAccess("") ? [{
          name: "Buscar Origem",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          cb: (e) => {
            this.$router.push({
              name: "listagem-notas-fiscais-devolucao-origem",
              params: {
                selectedNF: e,
                clientId: this.getClient().clientId,  // TODO por que passar clientId por query se é uma informação acessivel do vuex??
                nfId: e.nfId,
              },
            });
          },
        }] : [];
    },
  },
  created: function () {
    this.resourceCFOPs.get().then((response) => {
      this.opts.cfops = response;
    });
  },
  data: function () {
    return {
      cols: [
        /* NFE */
        {
          name: "NFE",
          type: this.$fieldTypes.SUBTITLE,
        },

        {
          key: "num_doc",
          name: "Nº Doc",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
          filterable: true,
        },
        {
          key: "nat_op",
          name: "Natureza Operação",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
        },
        {
          key: "chave_nfe",
          name: "Chave Nf-e",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 7,
        },
        {
          key: "data_emissao",
          name: "Dt Emissão",
          type: this.$fieldTypes.DATETIME,
          editable: false,
          colSize: 3,
          filterable: true,
        },
        {
          key: "serie",
          name: "Série",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        /* Fim NFE */
        /* Item NFE */
        {
          name: "Item NFE",
          type: this.$fieldTypes.SUBTITLE,
        },

        {
          key: "cfop",
          name: "CFOP",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "cfops", key: "cfop", name: "cfop" },
          editable: false,
          filterable: true,
          colSize: 2,
        },
        {
          key: "desc_cfop",
          name: "Descrição CFOP",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "codigo",
          name: "Cód. Item",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
          filterable: true,
        },
        {
          key: "descr_compl",
          name: "Desc. Complementar",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
          filterable: true,
        },
        {
          key: "quantidade",
          name: "Quantidade",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_item",
          name: "Valor Item",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_frete",
          name: "Valor Frete",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_seg",
          name: "Valor Seg.",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_outros",
          name: "Valor Outros",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_desc",
          name: "Valor Desc",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        /* Fim Item NFE */
        /* ICMS */
        {
          name: "ICMS",
          type: this.$fieldTypes.SUBTITLE,
        },

        {
          key: "cst_icms",
          name: "CST ICMS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_bc_icms",
          name: "Valor BC ICMS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "aliq_icms",
          name: "Aliq. ICMS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_icms",
          name: "Valor ICMS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
          filterable: true,
        },
        {
          key: "vl_icms_op",
          name: "Valor ICMS OP",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "p_dif",
          name: "P DIF",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_icms_dif",
          name: "Valor ICMS DIF",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_bc_icms_st",
          name: "Valor BC ICMS ST",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "aliq_st",
          name: "Aliq. ST",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "vl_icms_st",
          name: "Valor ICMS ST",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 3,
        },
        {
          key: "fcp_st",
          name: "FCP ST",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "deson_icms",
          name: "Deson. ICMS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 3,
        },
        {
          key: "difal_dest",
          name: "Difal Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "difal_remet",
          name: "Difal Remet.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "difal_fcp",
          name: "Difal FCP",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        /* FIM ICMS */
        /* IPI */
        {
          name: "IPI",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cst_ipi",
          name: "CST IPI",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_bc_ipi",
          name: "Valor BC IPI",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "aliq_ipi",
          name: "Aliq. IPI",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_ipi",
          name: "Valor IPI",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 6,
          filterable: true,
        },
        {
          key: "cod_enq",
          name: "Cód. Enq.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 6,
        },
        /* FIM IPI */
        /* PIS COFINS */
        {
          name: "PIS COFINS",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cst_pis_cofins",
          name: "CST PIS COFINS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_bc_pis_cofins",
          name: "Valor BC PIS COFINS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "aliq_pis",
          name: "Aliq. PIS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_pis",
          name: "Valor PIS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "aliq_cofins",
          name: "Aliq. COFINS",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 4,
        },
        {
          key: "vl_cofins",
          name: "Valor COFINS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        /* FIM PIS COFINS */
        /* OUTRAS INFORMAÇÕES */
        {
          name: "OUTRAS INFORMAÇÕES",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "nome_emit",
          name: "Nome Emit.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
          filterable: true,
        },
        {
          key: "cnpj_emit",
          name: "CNPJ Emit.",
          type: this.$fieldTypes.CNPJ,
          editable: false,
          colSize: 4,
        },
        {
          key: "nome_dest",
          name: "Nome Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
          filterable: true,
        },
        {
          key: "uf_dest",
          name: "UF Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
        },
        {
          key: "suframa_dest",
          name: "SUFRAMA Dest.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 2,
          filterable: true,
        },
        {
          key: "chave_ref",
          name: "Chave Ref.",
          type: this.$fieldTypes.TEXT,
          editable: false,
          colSize: 8,
        },
        {
          key: "valor_total_nf",
          name: "Valor Total NF",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 4,
        },
        {
          key: "inf_adic_prod",
          name: "Inf. Adic. Prod.",
          type: this.$fieldTypes.TEXTAREA,
          editable: false,
        },
        {
          key: "inf_comp",
          name: "Inf. Comp.",
          type: this.$fieldTypes.TEXTAREA,
          editable: false,
        },
        /* FIM OUTRAS INFORMAÇÕES */
        /* BASE DE ANÁLISE */
        {
          name: "BASE DE ANÁLISE",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "situacao_nf",
          name: "Situação NF",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "situacao_nf", key: "name", name: "name" },
          colSize: 6,
          filterable: true,
        },
        {
          key: "sit_incentivo",
          name: "Situação Incentivo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "sit_incentivo", key: "name", name: "name" },
          colSize: 6,
          filterable: true,
        },
        {
          key: "ncm_incentivada",
          name: "NCM Incentivada",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "ncm_incentivada", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "port_inf_compl",
          name: "Port. Inf. Compl.",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "port_inf_compl", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "obs",
          name: "Obs.",
          type: this.$fieldTypes.TEXTAREA,
        },
        /* FIM BASE DE ANÁLISE */
        /* INFORMAÇÕES */
        {
          name: "INFORMAÇÕES",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "vlr_oper_integral",
          name: "Valor Oper. Integral",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 6,
        },
        {
          key: "vlr_oper",
          name: "Valor Oper.",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          editable: false,
          colSize: 6,
        },
        {
          key: "produto",
          name: "Produto",
          type: this.$fieldTypes.TEXT,
          colSize: 5,
          filterable: true,
        },
        {
          key: "modelo",
          name: "Modelo",
          type: this.$fieldTypes.TEXT,
          colSize: 5,
          filterable: true,
        },
        {
          key: "periodo",
          name: "Período",
          type: this.$fieldTypes.MONTH,
          colSize: 2,
        },
        /* FIM INFORMAÇÕES */
        /* CLASSIFICAÇÃO */
        {
          name: "CLASSIFICAÇÃO",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "cred_pres",
          name: "Cred. Pres.",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "cred_pres", key: "name", name: "name" },
          colSize: 6,
        },
        {
          key: "comp_ped",
          name: "Compromisso P&D",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "comp_ped", key: "name", name: "name" },
          colSize: 6,
          filterable: true,
        },

        /* {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        }, */
      ],
      opts: {
        cfops: [],
        situacao_nf: [
          { name: "NF TERCEIROS" },
          { name: "AUTORIZADA" },
          { name: "DENEGADA" },
          { name: "INUTILIZADA" },
          { name: "CANCELADA" },
        ],
        sit_incentivo: [{ name: "NÃO" }, { name: "PPB" }, { name: "TecNac" }],
        ncm_incentivada: [{ name: "SIM" }, { name: "NÃO" }],
        port_inf_compl: [
          { name: "PORTARIA CORRETA" },
          { name: "PORTARIA ERRADA" },
          { name: "SEM PORTARIA" },
          { name: "PRODUTO NÃO INCENTIVADO" },
        ],
        cred_pres: [
          { name: "SAÍDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE SAÍDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE SAÍDA NÃO INCENTIVADA" },
          { name: "OPERAÇÃO NÃO INCENTIVADA" },
        ],
        comp_ped: [
          { name: "VENDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA NÃO INCENTIVADA" },
          { name: "VENDA ZFM" },
          { name: "DEVOLUÇÃO ZFM" },
          { name: "EXPORTAÇÃO" },
          { name: "DEVOLUÇÃO EXPORTAÇÃO" },
          { name: "OPERAÇÃO NÃO INCENTIVADA" },
        ],
      },
    };
  },
};
</script>
